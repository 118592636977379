import React from 'react';
import './servicesPage.scss';
import { Container } from 'react-bootstrap';

const ServicesPage = () => {
  return (
    <div className="servicesPage">
      <Container>
        <h1 className="mb-4">Our Services</h1>
        <div>
          <ul>
            <li>
              <h4>Feasibility Study</h4>
              <p>
                Our architectural feasibility study covers a range of essential aspects, including
                understanding project goals, evaluating real estate locations, determining building
                massing, and creating unit counts, floor plans, and elevations. The study culminates
                in delivering a comprehensive feasibility package that aids informed decision-making
                for successful project execution.
              </p>
            </li>
            <li>
              <h4>Architectural Design</h4>
              <p>
                (Schematic Design, Design Development, Construction Documents, Bidding, and
                Construction Administration) <br />
                We believe in a collaborative approach to architecture, where form, function, and
                technology merge with site, budget, and environmental considerations. We offer
                comprehensive architectural services encompassing building retrofits, renovations,
                additions, and new construction. Our conservation experts collaborate closely with
                our architectural team to produce detailed drawings and documents adapted to
                building restoration and conservation projects. Our design-driven organization
                creates functional and aesthetically pleasing environments that align with your
                vision and budget.
              </p>
            </li>
            <li>
              <h4>Interior Design</h4>
              <p>
                Our integrated approach extends to Interior Design Services, blurring the lines
                between where interior design begins and architecture ends. This enables us to
                achieve a perfect balance of art and science in a space, intelligently addressing
                all aspects that impact human habitation. We make the design process comfortable for
                our clients while being bold in our approach. <br />
                Our design philosophy thrives on building strong relationships with our clients and
                understanding their vision. We enjoy tackling design challenges and transforming
                ideas into real-life spatial experiences.
              </p>
            </li>
            <li>
              <h4>Project Management</h4>
              <p>
                The successful integration of design and construction, bringing together the Owner,
                Constructor, and Architect, doesn't happen by chance. It requires a skilled Project
                Manager or Integrator, as we call it, to lead the process of design, approvals,
                commissioning, and construction. We proactively seek opportunities to enhance team
                collaboration, adapt strategies to overcome challenges, and leverage our experience
                and creativity to steer projects towards success.
              </p>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default ServicesPage;
