import React from 'react';
import './homepage.scss';
import { Col, Container, Row } from 'react-bootstrap';
import missionStatement from '../../assets/images/Mission Statement-min.jpg';
import theFirm from '../../assets/images/The Firm-min.jpg';
import sustainability from '../../assets/images/Sustainability-min.jpg';
import team from '../../assets/images/Meet our team-min.jpg';

const Homepage = () => {
  return (
    <div className="homepage">
      <section className="section1">
        <div className="background-image">
          <div className={'text-wrapper'}>
            <p className="text-big">
              <span>IMAGINATION</span> IS <br /> KEY TO <br /> CREATIVITY
            </p>
            <p className="text-small">
              We are RT Architects. A full-service design and consulting firm who delivers design
              that is defined by your imagination.
            </p>
          </div>
        </div>
      </section>
      <section className="section2">
        <Container>
          <p className="section-title">Mission Statement</p>
          <Row className="align-items-center">
            <Col lg={5}>
              <p className="row-text mb-3">
                Our mission is to provide full architectural and interior services to designs that
                consist of creative, practical, and sustainable solutions that meets and surpasses
                our client’s expectations. We pride ourselves in the commitment level we provide to
                our valued clients by employing a hands-on method to finish each project on
                schedule, on budget, aesthetically pleasing, sustainable and functional to suite all
                your needs.
              </p>
              <p className="row-text mb-4 mb-lg-0">
                With over 20 years of experience, our expert staff commits to providing you’re the
                highest qualify of services for all your architectural goals.
              </p>
            </Col>
            <Col lg={7}>
              <div className="row-img-wrapper">
                <img src={missionStatement} alt="img" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section3">
        <Container>
          <p className="section-title">The Firm</p>
          <Row className="align-items-center">
            <Col lg={7}>
              <div className="row-img-wrapper">
                <img src={theFirm} alt="img" className="img-fluid" />
              </div>
            </Col>
            <Col lg={5}>
              <p className="row-text mb-3">
                Our story began in 2002 with Menhir Architectural Studio, focusing on residential,
                institutional, and commercial construction in historically significant Armenian
                cities. After a decade, Raffi founded Tashdjian Design in Toronto and is now a
                registered Architect under RT Architects in Ontario.
              </p>
              <p className="row-text mb-4 mb-lg-0">
                With vast expertise in architectural and interior design, working drawings, and
                construction management across institutional, commercial, and residential sectors.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section4 py-5">
        <Container>
          <Row>
            <Col md={6}>
              <div className="d-flex flex-column">
                <p className="section-title">Sustainability </p>
                <div className="row-img-wrapper mb-3">
                  <img src={sustainability} alt="img" className="img-fluid" />
                </div>
                <p className="row-text mb-4 mb-lg-0">
                  Sustainable approach is to make our projects energy efficient, environmentally
                  reliable, and ergonomically friendly.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-column">
                <p className="section-title">Meet Our Team</p>
                <div className="row-img-wrapper mb-3">
                  <img src={team} alt="img" className="img-fluid" />
                </div>
                <p className="row-text mb-4 mb-lg-0">
                  We are a strong, innovative team dedicated to providing the highest level of
                  service and design work.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Homepage;
