import React from 'react';
import './contactUspage.scss';
import { Col, Container, Row } from 'react-bootstrap';
import email from '../../assets/images/email.png';
import phone from '../../assets/images/telephone.png';

const ContactUsPage = () => {
  return (
    <div className="contactUsPage">
      <Container>
        <div className="map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1438.7850510409307!2d-79.4317692!3d43.844011!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2b9ca36a1d47%3A0xef9bacee22cb6740!2s8889%20York%20Regional%20Rd%201%2C%20Richmond%20Hill%2C%20ON%20L4C%206Z1%2C%20Canada!5e0!3m2!1sen!2sus!4v1691284706424!5m2!1sen!2sus"
            width="100%"
            height="500"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <Row>
          <Col>
            <div className="pt-4">
              <div className="line">
                <div className="title">
                  <div className="logo-wrapper">
                    <img src={email} alt="img" />
                  </div>
                  <span>Email</span>
                </div>
                <a href={'mailto:raffi@rtarchitects.ca'} className="desc">
                  raffi@rtarchitects.ca
                </a>
              </div>
              <div className="line">
                <div className="title">
                  <div className="logo-wrapper">
                    <img src={phone} alt="img" />
                  </div>
                  <span>Phone</span>
                </div>
                <a href={'tel:+14162702129'} className="desc">
                  +1 (416) 270-2129
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUsPage;
