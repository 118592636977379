import React from 'react';
import './projectspage.scss';
import { Container } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import {
  Ashbury,
  French,
  Hickory1,
  Hickory2,
  Hickory3,
  Hillview,
  Main,
  Ravine,
  Stillmeadow,
} from './mock';

const ProjectsPage = () => {
  return (
    <div className="projectsPage">
      <Container>
        <div className="row mb-5">
          <div className="col-md-6">
            <ImageGallery items={Hickory1} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Hickory Trio - 1 of 3</p>
              <p className="description">
                A harmonious blend of modern and traditional architectural elements resulting in a
                strikingly timeless and transitional new home. Nestled within the charming old
                neighborhood of Whitby, this architectural project seamlessly merges the best of
                both worlds to create a residence that captures the essence of contemporary living
                while preserving the warmth of tradition. The design thoughtfully considers
                functionality, offering spaces that not only cater to the needs of a modern family
                but also foster a sense of coziness and togetherness. With a strong emphasis on
                family-oriented living, the Hickory Residence is destined to be a cherished haven
                for generations to come.
              </p>
              <p className="sizes">3,400 sq ft</p>
              <p className="on">2018</p>
              <p className="on">Whitby, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5 flex-row-reverse">
          <div className="col-md-6">
            <ImageGallery items={Hickory2} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Hickory Trio - 2 of 3 </p>
              <p className="description">
                This two-story bungalow features a timeless architectural design, skillfully
                blending modern and classic elements. The functional layout ensures ample natural
                light, creating a warm and inviting interior. Crafted with precision and care, it
                stands as a testament to sustainable living solutions for the community.
              </p>
              <p className="sizes">3,500 sq ft</p>
              <p className="on">2018</p>
              <p className="on">Whitby, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <ImageGallery items={Hickory3} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Hickory Trio - 3 of 3</p>
              <p className="description">
                Drawing inspiration from timeless architecture, this bungalow embraces a refined and
                sophisticated color palette, exuding a sense of elegance and tranquility. As part of
                a trio of homes thoughtfully designed side by side, it effortlessly balances
                functionality, inviting warmth, and luxurious amenities for an unparalleled living
                experience.
              </p>
              <p className="sizes">2,500 sq ft</p>
              <p className="on">2018</p>
              <p className="on">Whitby, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5 flex-row-reverse">
          <div className="col-md-6">
            <ImageGallery items={French} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> French Revival Bungaloft: </p>
              <p className="description">
                A Thoughtful Topographical Design nestled in a corner of a cul-de-sac, this
                bungaloft presented challenges due to its unique topography. We overcame the
                constraints and leveraged opportunities to create an L-shaped layout that maximizes
                natural light, ventilation, and functionality. Privacy and outdoor space were
                essential client requirements, and we ensured both were carefully integrated. The
                loft space above the garage serves as a versatile recreational area with a vaulted
                ceiling and distinct roof design. The French Revival Bungaloft is a testament to
                thoughtful design and seamless integration with its surroundings.
              </p>
              <p className="sizes">5,475 sq ft</p>
              <p className="on">2018</p>
              <p className="on">Whitby, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <ImageGallery items={Main} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Main Street Residence:</p>
              <p className="description">
                The modern two-story single-family house features a light-colored facade. The
                carefully planned expansion introduces open spaces while maintaining its inviting
                atmosphere. The facade is adorned with big windows, dark wood siding, and refined
                beige stone, exuding contemporary elegance and sophistication.
              </p>
              <p className="sizes">4,300 sq ft</p>
              <p className="on">2018</p>
              <p className="on">Markham, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5 flex-row-reverse">
          <div className="col-md-6">
            <ImageGallery items={Ashbury} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Ashbury Residence:</p>
              <p className="description">
                In the heart of Oakville stands a stunning two-story single-family dwelling,
                artfully embracing Bohemian architecture as per our client's preference. The
                exterior showcases its unique charm, while inside, generous open spaces cater to
                every function. A light, warm-toned color palette with simple textures as accents
                complements the free-spirited nature of this captivating design.
              </p>
              <p className="sizes">3,000 sq ft</p>
              <p className="on">2018</p>
              <p className="on">Oakville, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <ImageGallery items={Hillview} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title">Hillview Residence:</p>
              <p className="description">
                Designed to accommodate the needs of a growing family of four, the "Elegant
                Ascension" is a striking top-up project that extends the existing bungalow's floor
                plan. The carefully crafted expansion introduces open spaces while retaining the
                cherished cozy atmosphere. The facade, adorned with dark blue siding and refined
                grey stone, exudes contemporary elegance. This sophisticated home perfectly caters
                to the evolving lifestyle of the family while seamlessly maintaining the character
                of their cherished abode.
              </p>
              <p className="sizes">3,400 sq ft</p>
              <p className="on">2017</p>
              <p className="on">Oakville, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5 flex-row-reverse">
          <div className="col-md-6">
            <ImageGallery items={Stillmeadow} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Stillmeadow Residence: </p>
              <p className="description">
                Upon acquiring this aged bungalow, the client embarked on a journey to reimagine its
                potential, resulting in a breathtaking architectural metamorphosis. The home's new
                open-plan interior seamlessly intertwines with a thoughtfully redesigned facade,
                forging a cohesive and visually stunning whole. This transformative process has not
                only enriched the dwelling but also elevated its presence within the mature
                neighborhood. With a unified architectural vision, this renewed bungalow stands as
                an enduring symbol of timeless elegance.
              </p>
              <p className="sizes">2,700 sq ft</p>
              <p className="on">2018</p>
              <p className="on">Mississauga, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <ImageGallery items={Ravine} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title">Ravine-side Bungaloft:</p>
              <p className="description">
                Embracing Natural Constraints nestled gracefully alongside a picturesque ravine,
                this bungaloft project artfully embraces the captivating natural landscape while
                addressing the unique constraints that accompany such a setting. With the ravine as
                a stunning backdrop, our design thoughtfully integrates the home, offering
                breathtaking views and a seamless connection to nature. The split bungaloft layout
                was carefully planned to optimize the experience of the ravine setting while
                ensuring a functional and comfortable living space. Challenges such as slope
                stability and environmental preservation were meticulously addressed to maintain the
                ravine's ecological balance. The Ravine-side Bungaloft exemplifies our commitment to
                sustainable design and creating a home that coexists harmoniously with the beauty of
                the ravine environment.
              </p>
              <p className="sizes">3,800 sq ft</p>
              <p className="on">2020</p>
              <p className="on">Pickering, On</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProjectsPage;
