import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './components/navbar';
import Homepage from './pages/homepage';
import ProjectsPage from './pages/projectspage';
import OngoingProjectsPage from './pages/ongoingProjectspage';
import ContactUsPage from './pages/contactUspage';
import Footer from './components/footer';
import ServicesPage from './pages/servicesPage';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path={'/services'} element={<ServicesPage />} />
        <Route path={'/projects'} element={<ProjectsPage />} />
        <Route path={'/ongoing-projects'} element={<OngoingProjectsPage />} />
        <Route path={'/contact-us'} element={<ContactUsPage />} />
        <Route path={'/*'} element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
