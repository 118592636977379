import hickory11 from '../../assets/projects/Hickory Trio - 1 of 3/Hickory Street 715.1-min.jpg';
import hickory12 from '../../assets/projects/Hickory Trio - 1 of 3/Hickory Street 715.2-min.jpg';
import hickory13 from '../../assets/projects/Hickory Trio - 1 of 3/Hickory Street 715.3-min.jpg';
import hickory14 from '../../assets/projects/Hickory Trio - 1 of 3/Hickory Street 715.4-min.jpg';
import hickory15 from '../../assets/projects/Hickory Trio - 1 of 3/Hickory Street 715.5-min.jpg';
import hickory16 from '../../assets/projects/Hickory Trio - 1 of 3/Hickory Street 715.6-min.jpg';
import hickory17 from '../../assets/projects/Hickory Trio - 1 of 3/Hickory Street 715.7-min.jpg';
import hickory18 from '../../assets/projects/Hickory Trio - 1 of 3/Hickory Street 715.8-min.jpg';
import hickory19 from '../../assets/projects/Hickory Trio - 1 of 3/Hickory Street 715.9-min.jpg';

import hickory21 from '../../assets/projects/Hickory Trio - 2 of 3/Hickory Street 717.1-min.jpg';
import hickory22 from '../../assets/projects/Hickory Trio - 2 of 3/Hickory Street 717.2-min.jpg';
import hickory23 from '../../assets/projects/Hickory Trio - 2 of 3/Hickory Street 717.3-min.jpg';
import hickory24 from '../../assets/projects/Hickory Trio - 2 of 3/Hickory Street 717.4-min.jpg';
import hickory25 from '../../assets/projects/Hickory Trio - 2 of 3/Hickory Street 717.5-min.jpg';
import hickory26 from '../../assets/projects/Hickory Trio - 2 of 3/Hickory Street 717.6-min.jpg';
import hickory27 from '../../assets/projects/Hickory Trio - 2 of 3/Hickory Street 717.7-min.jpg';
import hickory28 from '../../assets/projects/Hickory Trio - 2 of 3/Hickory Street 717.8-min.jpg';
import hickory29 from '../../assets/projects/Hickory Trio - 2 of 3/Hickory Street 717.9-min.jpg';

import hickory31 from '../../assets/projects/Hickory Trio - 3 of 3/Hickory Street 719.1-min.png';
import hickory32 from '../../assets/projects/Hickory Trio - 3 of 3/Hickory Street 719.2-min.jpg';
import hickory33 from '../../assets/projects/Hickory Trio - 3 of 3/Hickory Street 719.3-min.jpg';
import hickory34 from '../../assets/projects/Hickory Trio - 3 of 3/Hickory Street 719.4-min.jpg';
import hickory35 from '../../assets/projects/Hickory Trio - 3 of 3/Hickory Street 719.5-min.jpg';
import hickory36 from '../../assets/projects/Hickory Trio - 3 of 3/Hickory Street 719.6-min.jpg';
import hickory37 from '../../assets/projects/Hickory Trio - 3 of 3/Hickory Street 719.7-min.jpg';
import hickory38 from '../../assets/projects/Hickory Trio - 3 of 3/Hickory Street 719.8-min.jpg';
import hickory39 from '../../assets/projects/Hickory Trio - 3 of 3/Hickory Street 719.9-min.jpg';

import French1 from '../../assets/projects/17 Sandpiper Crt/Sandpiper 17.1-min.jpg';
import French2 from '../../assets/projects/17 Sandpiper Crt/Sandpiper 17.2-min.jpg';
import French3 from '../../assets/projects/17 Sandpiper Crt/Sandpiper 17.3-min.jpg';
import French4 from '../../assets/projects/17 Sandpiper Crt/Sandpiper 17.4-min.jpg';
import French5 from '../../assets/projects/17 Sandpiper Crt/Sandpiper 17.5-min.jpg';
import French6 from '../../assets/projects/17 Sandpiper Crt/Sandpiper 17.6-min.jpg';
import French7 from '../../assets/projects/17 Sandpiper Crt/Sandpiper 17.7-min.jpg';
import French8 from '../../assets/projects/17 Sandpiper Crt/Sandpiper 17.8-min.jpg';
import French9 from '../../assets/projects/17 Sandpiper Crt/Sandpiper 17.9-min.jpg';

import Main1 from '../../assets/projects/Main Street Residence/Main Street 304.1-min.jpg';
import Main2 from '../../assets/projects/Main Street Residence/Main Street 304.2-min.jpg';
import Main3 from '../../assets/projects/Main Street Residence/Main Street 304.3-min.jpg';
import Main4 from '../../assets/projects/Main Street Residence/Main Street 304.4-min.jpg';
import Main5 from '../../assets/projects/Main Street Residence/Main Street 304.5-min.jpg';
import Main6 from '../../assets/projects/Main Street Residence/Main Street 304.6-min.jpg';

import Ashbury1 from '../../assets/projects/325 Ashbury Rd/Ashbury Rd 325.1-min.jpg';
import Ashbury2 from '../../assets/projects/325 Ashbury Rd/Ashbury Rd 325.2-min.jpg';
import Ashbury3 from '../../assets/projects/325 Ashbury Rd/Ashbury Rd 325.3-min.jpg';
import Ashbury4 from '../../assets/projects/325 Ashbury Rd/Ashbury Rd 325.4-min.jpg';
import Ashbury5 from '../../assets/projects/325 Ashbury Rd/Ashbury Rd 325.5-min.jpg';
import Ashbury6 from '../../assets/projects/325 Ashbury Rd/Ashbury Rd 325.6-min.jpg';
import Ashbury7 from '../../assets/projects/325 Ashbury Rd/Ashbury Rd 325.7-min.jpg';
import Ashbury8 from '../../assets/projects/325 Ashbury Rd/Ashbury Rd 325.8-min.jpg';
import Ashbury9 from '../../assets/projects/325 Ashbury Rd/Ashbury Rd 325.9-min.jpg';

import Hillview1 from '../../assets/projects/Hillview Residence/Hillview Cres 1316.1-min.jpg';
import Hillview2 from '../../assets/projects/Hillview Residence/Hillview Cres 1316.2-min.jpg';
import Hillview3 from '../../assets/projects/Hillview Residence/Hillview Cres 1316.3-min.jpg';
import Hillview4 from '../../assets/projects/Hillview Residence/Hillview Cres 1316.4-min.jpg';
import Hillview5 from '../../assets/projects/Hillview Residence/Hillview Cres 1316.5-min.jpg';
import Hillview6 from '../../assets/projects/Hillview Residence/Hillview Cres 1316.6-min.jpg';
import Hillview7 from '../../assets/projects/Hillview Residence/Hillview Cres 1316.7-min.jpg';
import Hillview8 from '../../assets/projects/Hillview Residence/Hillview Cres 1316.8-min.jpg';
import Hillview9 from '../../assets/projects/Hillview Residence/Hillview Cres 1316.9-min.jpg';

import Stillmeadow1 from '../../assets/projects/Stillmeadow Residence/Stillmeadow rd 2211.1-min.png';
import Stillmeadow2 from '../../assets/projects/Stillmeadow Residence/Stillmeadow Rd 2211.2-min.jpg';
import Stillmeadow3 from '../../assets/projects/Stillmeadow Residence/Stillmeadow Rd 2211.3-min.jpg';
import Stillmeadow4 from '../../assets/projects/Stillmeadow Residence/Stillmeadow Rd 2211.4-min.jpg';
import Stillmeadow5 from '../../assets/projects/Stillmeadow Residence/Stillmeadow Rd 2211.5-min.jpg';
import Stillmeadow6 from '../../assets/projects/Stillmeadow Residence/Stillmeadow Rd 2211.6-min.jpg';
import Stillmeadow7 from '../../assets/projects/Stillmeadow Residence/Stillmeadow Rd 2211.7-min.jpg';
import Stillmeadow8 from '../../assets/projects/Stillmeadow Residence/Stillmeadow Rd 2211.8-min.jpg';
import Stillmeadow9 from '../../assets/projects/Stillmeadow Residence/Stillmeadow Rd 2211.9-min.jpg';

import Ravine1 from '../../assets/projects/Ravine-side Bungaloft/Sideline 3905.1-min.jpg';
import Ravine2 from '../../assets/projects/Ravine-side Bungaloft/Sideline 3905.2-min.jpg';
import Ravine3 from '../../assets/projects/Ravine-side Bungaloft/Sideline 3905.3-min.jpg';
import Ravine4 from '../../assets/projects/Ravine-side Bungaloft/Sideline 3905.4-min.jpg';
import Ravine5 from '../../assets/projects/Ravine-side Bungaloft/Sideline 3905.5-min.jpg';

export const Hickory1 = [
  {
    original: hickory11,
  },
  {
    original: hickory12,
  },
  {
    original: hickory13,
  },
  {
    original: hickory14,
  },
  {
    original: hickory15,
  },
  {
    original: hickory16,
  },
  {
    original: hickory17,
  },
  {
    original: hickory18,
  },
  {
    original: hickory19,
  },
];
export const Hickory2 = [
  {
    original: hickory21,
  },
  {
    original: hickory22,
  },
  {
    original: hickory23,
  },
  {
    original: hickory24,
  },
  {
    original: hickory25,
  },
  {
    original: hickory26,
  },
  {
    original: hickory27,
  },
  {
    original: hickory28,
  },
  {
    original: hickory29,
  },
];
export const Hickory3 = [
  {
    original: hickory31,
  },
  {
    original: hickory32,
  },
  {
    original: hickory33,
  },
  {
    original: hickory34,
  },
  {
    original: hickory35,
  },
  {
    original: hickory36,
  },
  {
    original: hickory37,
  },
  {
    original: hickory38,
  },
  {
    original: hickory39,
  },
];
export const French = [
  {
    original: French1,
  },
  {
    original: French2,
  },
  {
    original: French3,
  },
  {
    original: French4,
  },
  {
    original: French5,
  },
  {
    original: French6,
  },
  {
    original: French7,
  },
  {
    original: French8,
  },
  {
    original: French9,
  },
];
export const Main = [
  {
    original: Main1,
  },
  {
    original: Main2,
  },
  {
    original: Main3,
  },
  {
    original: Main4,
  },
  {
    original: Main5,
  },
  {
    original: Main6,
  },
];
export const Ashbury = [
  {
    original: Ashbury1,
  },
  {
    original: Ashbury2,
  },
  {
    original: Ashbury3,
  },
  {
    original: Ashbury4,
  },
  {
    original: Ashbury5,
  },
  {
    original: Ashbury6,
  },
  {
    original: Ashbury7,
  },
  {
    original: Ashbury8,
  },
  {
    original: Ashbury9,
  },
];
export const Hillview = [
  {
    original: Hillview1,
  },
  {
    original: Hillview2,
  },
  {
    original: Hillview3,
  },
  {
    original: Hillview4,
  },
  {
    original: Hillview5,
  },
  {
    original: Hillview6,
  },
  {
    original: Hillview7,
  },
  {
    original: Hillview8,
  },
  {
    original: Hillview9,
  },
];
export const Stillmeadow = [
  {
    original: Stillmeadow1,
  },
  {
    original: Stillmeadow2,
  },
  {
    original: Stillmeadow3,
  },
  {
    original: Stillmeadow4,
  },
  {
    original: Stillmeadow5,
  },
  {
    original: Stillmeadow6,
  },
  {
    original: Stillmeadow7,
  },
  {
    original: Stillmeadow8,
  },
  {
    original: Stillmeadow9,
  },
];
export const Ravine = [
  {
    original: Ravine1,
  },
  {
    original: Ravine2,
  },
  {
    original: Ravine3,
  },
  {
    original: Ravine4,
  },
  {
    original: Ravine5,
  },
];
