import React from 'react';
import './ongoingProjectspage.scss';
import ImageGallery from 'react-image-gallery';
import { Container } from 'react-bootstrap';

import onGoing1 from '../../assets/ongoingProjects/Ainsley Gardens 2.1-min.jpg';
import onGoing2 from '../../assets/ongoingProjects/Ainsley Gardens 2.2-min.jpg';
import onGoing3 from '../../assets/ongoingProjects/Devondale Ave 46 -min.jpg';
import onGoing4 from '../../assets/ongoingProjects/Griffin  Way 449.1-min.jpg';
import onGoing5 from '../../assets/ongoingProjects/Griffin  Way 449.2-min.jpg';
import onGoing6 from '../../assets/ongoingProjects/Griffin  Way 449.3-min.jpg';
import onGoing7 from '../../assets/ongoingProjects/Heath Str W 108-min.jpg';
import onGoing8 from '../../assets/ongoingProjects/Niagara-1-min.jpg';
import onGoing9 from '../../assets/ongoingProjects/Niagara-2-min.jpg';
import onGoing10 from '../../assets/ongoingProjects/Niagara-3-min.jpg';
import onGoing11 from '../../assets/ongoingProjects/Orillia Retreat-1-min.jpg';
import onGoing12 from '../../assets/ongoingProjects/Orillia Retreat-2-min.jpg';
import onGoing13 from '../../assets/ongoingProjects/Overbrook Pl 147.1-min.jpg';
import onGoing14 from '../../assets/ongoingProjects/Overbrook Pl 147.2-min.jpg';
import onGoing15 from '../../assets/ongoingProjects/Overbrook Pl 147.3-min.jpg';
import onGoing16 from '../../assets/ongoingProjects/South Ave 344-min.jpg';
import onGoing17 from '../../assets/ongoingProjects/Trudale Court 507-min.jpg';

const OngoingProjectsPage = () => {
  const Trudale = [
    {
      original: onGoing17,
    },
  ];
  const Devondale = [
    {
      original: onGoing3,
    },
  ];
  const Ainsley = [
    {
      original: onGoing1,
    },
    {
      original: onGoing2,
    },
  ];
  const Heath = [
    {
      original: onGoing7,
    },
  ];
  const Overbrook = [
    {
      original: onGoing13,
    },
    {
      original: onGoing14,
    },
    {
      original: onGoing15,
    },
  ];
  const South = [
    {
      original: onGoing16,
    },
  ];
  const Griffin = [
    {
      original: onGoing4,
    },
    {
      original: onGoing5,
    },
    {
      original: onGoing6,
    },
  ];
  const Orillia = [
    {
      original: onGoing11,
    },
    {
      original: onGoing12,
    },
  ];
  const Niagara = [
    {
      original: onGoing8,
    },
    {
      original: onGoing9,
    },
    {
      original: onGoing10,
    },
  ];

  return (
    <div className="ongoingProjects">
      <Container>
        <div className="row mb-5">
          <div className="col-md-6">
            <ImageGallery items={Trudale} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Trudale Residence: </p>
              <p className="description">
                A modern two-storey single-family dwelling features a dark facade with wood siding
                for warmth and texture, harmonizing with the natural beauty of its surroundings.
                Large windows frame picturesque views, creating a strong connection between the
                interior and the captivating landscape. The contemporary design effortlessly
                integrates with the environment, offering a tranquil living experience. Nestled in a
                mature neighborhood, this residence embraces modern living amidst the natural beauty
                of the its surrounding.
              </p>
              <p className="sizes">3,200 sq ft</p>
              <p className="on">Oakville, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5 flex-row-reverse">
          <div className="col-md-6">
            <ImageGallery items={Devondale} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Devondale Residence: </p>
              <p className="description">
                A contemporary two-story house for a single family showcases an elegant, dark
                exterior with wooden siding, providing both a cozy feel and a visually pleasing
                blend with the scenic environment. Large windows capture stunning views,
                establishing a seamless link between the indoors and the captivating landscape. This
                modern design effortlessly melds with its surroundings, ensuring a peaceful and
                serene living experience. Situated in a well-established neighborhood, this dwelling
                embraces modern living amidst the innate charm of its natural setting.
              </p>
              <p className="sizes">3,200 sq ft</p>
              <p className="on">North York, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <ImageGallery items={Ainsley} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Ainsley Garden Residence:</p>
              <p className="description">
                Architectural Design Partner: AOF Design Studio <br /> This architectural marvel is
                a testament to the artistry and design excellence of transitional modern aesthetics.
                The primary focus of this project was to seamlessly merge contemporary elements with
                timeless elegance. The goal was to create a space that embodies both functionality
                and beauty.
              </p>
              <p className="sizes">4,300 sq ft</p>
              <p className="on">Etobicoke, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5 flex-row-reverse">
          <div className="col-md-6">
            <ImageGallery items={Heath} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Heath Residence (Semi-Detached houses) </p>
              <p className="description">
                Architectural Design Partner: AOF Design Studio <br /> Welcome to this magnificent
                three-storey semi-detached house in Toronto, exuding the timeless elegance of a
                French chateau. The smooth white stone exterior and dark-colored window frames
                create a striking and refined look. <br />
                With a luxurious interior equipped with elevator effortlessly connects all three
                levels, ensuring convenience and accessibility. Soaring ceilings, intricate molding,
                and French-inspired decor add opulence and warmth. <br />
                This exceptional home seamlessly blends classical charm with modern comfort,
                offering an enchanting living experience that will be cherished for generations to
                come. Welcome to your dream chateau-style retreat in Toronto.
              </p>
              <p className="sizes">7,600 sq ft</p>
              <p className="on">Toronto, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <ImageGallery items={Overbrook} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Overbrook Place Residence (5 Sem- Detached houses)</p>
              <p className="description">
                Architectural Design Partner: AOF Design Studio <br />
                The project's brief was not like any other due to its unique location adjacent to a
                place of worship with a century-long history of serving the community. Our approach
                aimed to create a development that would stand the test of time and become an
                integral part of the community, built by and for the people it serves. To achieve
                this, we carefully selected timeless design elements and materials, such as a
                combination of blonde brick on the lower levels and timber for the upper floor,
                creating a balance of durability and warmth. The entrance, thoughtfully indented and
                encased in a wrap-around metal skirt angled towards the sky, exuded a welcoming
                aura, as if inviting visitors inside with open arms. A double-height void space at
                the front entrance added grandeur and openness to foster a welcoming ambiance. The
                strategic placement of the kitchen in the middle of the home served as a central
                hub, connecting the dining, and living areas and encouraging communal interactions.
                Considering the challenge of limited natural lighting due to the semi-detached
                nature and neighboring houses, we ingeniously utilized void areas around the stairs
                to capture light from above and disperse it throughout the central areas of the
                home. Moreover, large front and back windows with overhangs were incorporated to
                allow abundant natural light while minimizing direct sunlight exposure. Overall, our
                focus was on creating a generous, well-lit, and welcoming space that not only met
                the functional needs of the residents but also contributed positively to the
                community, seamlessly blending aesthetics with functionality, resulting in a home
                that stood as a testament to the enduring spirit of the community it served.
              </p>
              <p className="sizes">25,800 sq ft</p>
              <p className="on">Nort York, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5 flex-row-reverse">
          <div className="col-md-6">
            <ImageGallery items={South} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> South Street Residence: </p>
              <p className="description">
                This modern two-storey single-family dwelling has a dark facade with wood siding,
                blending well with the natural surroundings. Expansive windows offer picturesque
                views and create a strong indoor-outdoor connection. The contemporary design
                seamlessly integrates with the environment, providing a tranquil living experience
                in a mature neighborhood.
              </p>
              <p className="sizes">3,400 sq ft</p>
              <p className="on">Uxbridge, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <ImageGallery items={Griffin} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Griffin Way Warehouse:</p>
              <p className="description">
                A commercial three-storey 44,500 sq ft office and warehouse building in Woodstock
                Ontario, designed with an emphasis on the front offices. The offices feature large
                window screens and aluminum composite paneling, creating an attractive and modern
                facade that highlights the entrance. The combination of functional office spaces and
                warehouse facilities makes it a practical and versatile space for businesses.
              </p>
              <p className="sizes">44,500 sq ft</p>
              <p className="on">Woodstock, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5 flex-row-reverse">
          <div className="col-md-6">
            <ImageGallery items={Orillia} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Orillia Retreat: </p>
              <p className="description">
                Architectural Design Partner: AOF Design Studio <br /> A modern barn retreat awaits
                in the serene countryside north of Toronto. Embracing its natural context, this
                architectural gem maximizes year-round natural light. The timeless dark metal facade
                creates a striking contrast with the lush green surroundings, ensuring its enduring
                presence for years to come. It's a harmonious blend of contemporary design and
                everlasting charm.
              </p>
              <p className="sizes">3,400 sq ft</p>
              <p className="on">Orillia, On</p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <ImageGallery items={Niagara} showPlayButton={false} showFullscreenButton={false} />
          </div>
          <div className="col-md-6">
            <div className="info">
              <p className="title"> Niagara Residence:</p>
              <p className="description">
                A modern two-storey single-family dwelling features with several exterior finishes
                and texture, harmonizing with the natural beauty of its surroundings. With the
                ravine as a stunning backdrop, our design thoughtfully integrates the home, offering
                breathtaking views and a seamless connection to nature. <br />
                The contemporary design effortlessly blends into the environment, ensuring the
                residence complements and enhances the beauty of its surroundings. The strategic
                placement of the large windows maximizes the enjoyment of the ravine views from
                various rooms within the house. <br />
                Inside, the two-storey layout provides ample space for comfortable living. The
                ground floor boasts an open and inviting living area, while the upper floor
                accommodates private bedrooms and functional spaces. This well-planned layout
                ensures the residents can fully appreciate the beauty of the ravine setting while
                enjoying the comfort and convenience of a modern home.
              </p>
              <p className="sizes">4,050 sq ft</p>
              <p className="on">Niagara, On</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OngoingProjectsPage;
