import React, { useState } from 'react';
import './navbar.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import logo from '../../assets/images/logo.png';

const NavBar = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <Navbar variant="dark" expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand onClick={() => navigate('/')}>
            <img src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto navlinks">
              <NavLink to={'/'} className="px-2 py-2 py-lg-0" onClick={() => setExpanded(false)}>
                Home
              </NavLink>
              <NavLink
                to={'/services'}
                className="px-2 py-2 py-lg-0"
                onClick={() => setExpanded(false)}
              >
                Services
              </NavLink>
              <NavLink
                to={'/projects'}
                className="px-2 py-2 py-lg-0"
                onClick={() => setExpanded(false)}
              >
                Projects
              </NavLink>
              <NavLink
                to={'/ongoing-projects'}
                className="px-2 py-2 py-lg-0"
                onClick={() => setExpanded(false)}
              >
                Ongoing Projects
              </NavLink>
              <NavLink
                to={'/contact-us'}
                className="px-2 py-2 py-lg-0"
                onClick={() => setExpanded(false)}
              >
                Contact us
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
